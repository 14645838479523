@font-face {
font-family: 'DMSans';
src: url(/_next/static/media/c25915a061a39427-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'DMSans Fallback';src: local("Arial");ascent-override: 93.39%;descent-override: 29.18%;line-gap-override: 0.00%;size-adjust: 106.22%
}.__className_2393a7 {font-family: 'DMSans', 'DMSans Fallback'
}.__variable_2393a7 {--font-dm-sans: 'DMSans', 'DMSans Fallback'
}

@font-face {
font-family: 'Rift';
src: url(/_next/static/media/9ab4a4154f4c0ff2-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'Rift';
src: url(/_next/static/media/2bd3d6662d626761-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'Rift';
src: url(/_next/static/media/46bb5f2cf08f78f3-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'Rift';
src: url(/_next/static/media/b185edd6cc6d7c38-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'Rift';
src: url(/_next/static/media/ebe38848cd86af38-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'Rift Fallback';src: local("Arial");ascent-override: 118.22%;descent-override: 26.54%;line-gap-override: 0.00%;size-adjust: 82.90%
}.__className_854e67 {font-family: 'Rift', 'Rift Fallback'
}.__variable_854e67 {--font-rift: 'Rift', 'Rift Fallback'
}

/* latin-ext */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/4f2204fa15b9b11a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/07a54048a9278940-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Outfit Fallback';src: local("Arial");ascent-override: 100.18%;descent-override: 26.05%;line-gap-override: 0.00%;size-adjust: 99.82%
}.__className_ad50fd {font-family: 'Outfit', 'Outfit Fallback';font-style: normal
}.__variable_ad50fd {--font-outfit: 'Outfit', 'Outfit Fallback'
}

/* latin-ext */
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Figtree Fallback';src: local("Arial");ascent-override: 94.32%;descent-override: 24.82%;line-gap-override: 0.00%;size-adjust: 100.72%
}.__className_7d5794 {font-family: 'Figtree', 'Figtree Fallback';font-style: normal
}.__variable_7d5794 {--font-figtree: 'Figtree', 'Figtree Fallback'
}

/* latin-ext */
@font-face {
  font-family: 'Instrument Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/45cf6616ee386f2d-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Instrument Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2abeb75a5e332dc3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Instrument Serif Fallback';src: local("Times New Roman");ascent-override: 117.94%;descent-override: 36.93%;line-gap-override: 0.00%;size-adjust: 83.94%
}.__className_127ae0 {font-family: 'Instrument Serif', 'Instrument Serif Fallback';font-weight: 400;font-style: normal
}.__variable_127ae0 {--font-instrument: 'Instrument Serif', 'Instrument Serif Fallback'
}

